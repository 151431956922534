import React from 'react'
import styled from 'styled-components'
import ParallaxShape from '../ParallaxShape'
import { graphql } from 'gatsby'
import { breakpoints, colors } from '../../theme'
import TileList from '../TileList'
import ArtisanTile from '../ArtisanTile'

const IndexArtisans = ({ settings, title, subTitle, artisans }) => {
  return (
    <StyledIndexArtisans>
      <div className="our-artisans-container">
        <ParallaxShape
          x={90}
          y={-10}
          shapeStyle={{
            top: '200vh',
            width: '200px',
            height: '400px',
            border: '140px solid ' + colors.brand.beige,
            borderLeft: 'none',
            borderBottomRightRadius: '800px',
            borderTopRightRadius: '800px',
            transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
            opacity: '0.5',
          }}
        />
        <div className="our-artisans-title">
          <h3>{title}</h3>
          <p className="subtitle">{subTitle}</p>
        </div>
        <TileList
          settings={{
            ...settings,
            slidesToShow: 1,
            infinite: true,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 5000,
            cssEase: 'linear',
            fade: true,
            responsive: [
              {
                settings: {
                  arrows: true,
                },
              },
            ],
          }}
          tiles={artisans}
          renderTile={<ArtisanTile title={title} subTitle={subTitle} />}
          className="artisan-tile-list"
        />
      </div>
    </StyledIndexArtisans>
  )
}

const StyledIndexArtisans = styled.form`
  .our-artisans-title {
    color: ${colors.brand.green};
  }
  .our-artisans-container {
    margin-top: 100px;
    position: relative;
    z-index: 1;
    .our-artisans-title {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.desktop}px) {
    .our-artisans-container {
      width: 100%;
      position: relative;
      margin-top: 181px;
      .our-artisans-title {
        display: block;
        padding-left: 7.5%;
        h3 {
          font-size: 27px;
        }
        .subtitle {
          font-size: 17px;
          margin-bottom: 36px;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.mobile}px) {
    .our-artisans-container {
      margin-top: 100px;
    }
  }
`

export default IndexArtisans
export { StyledIndexArtisans }

export const IndexBannerHeaderFragment = graphql`
  fragment IndexArtisansFragment on ContentfulArtisanList {
    title
    subTitle
    artisans {
      ...ArtisanFragment
    }
  }
`
