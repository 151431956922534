import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import Banner from '../components/banners/Banner'
import DoubleBanner from '../components/DoubleBanner'
import TileList, { StyledTileList } from '../components/TileList'
import Card from '../components/Card'
import Footer from '../components/Footer'
import { borders, breakpoints, colors, homeLayout } from '../theme'
import Navbar from '../components/Navbar'
import { StyledEventFormatSelector } from '../components/EventFormatSelector'
import { StyledButton } from '@monbanquet/crumble'
import '../reset.css'
import { StyledLargeBanner } from '../components/LargeBanner'
import GoogleRatingContainer from '../components/GoogleRatingContainer'
import EventCard from '../components/EventCard'
import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxShape, { StyledParallaxShape } from '../components/ParallaxShape'
import BottomBanner from '../components/banners/BannerBottom'
import IndexBannerHeader from '../components/index/IndexBannerHeader'
import IndexArtisans from '../components/index/IndexArtisans'

// https://react-slick.neostack.com/docs/api#settings
const settings = {
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: false,
  mobileFirst: true,
  // adaptiveHeight: true,
  responsive: [
    {
      breakpoint: breakpoints.desktop + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpoints.tablet + 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
}

const IndexPage = ({ data: { page } }) => {
  //Disable all links for the big Vibe events !!
  page.bigVibeList.vibeList.forEach(item => {
    item.link = ' '
    item.linkedPage = null
  })

  return (
    <StyledIndexPage>
      <ParallaxProvider>
        <Helmet
          title={page.seoTitle}
          meta={[
            {
              name: 'description',
              content: page.seoDescription.seoDescription,
            },
            { name: 'keywords', content: page.seoKeywords.seoKeywords },
          ]}
        />
        <Navbar />
        <div className="page-content">
          <IndexBannerHeader {...page.bannerHeader} />
          <Banner {...page.banner4} />
          <div className="our-menus-container">
            <ParallaxShape
              x={85}
              y={0}
              shapeStyle={{
                width: '400px',
                height: '200px',
                border: '140px solid ' + colors.brand.beige,
                borderBottom: 'none',
                borderTopLeftRadius: '400px',
                borderTopRightRadius: '400px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                opacity: '0.5',
              }}
            />
            <ParallaxShape
              x={-10}
              y={30}
              shapeStyle={{
                width: '400px',
                height: '400px',
                border: '140px solid ' + colors.brand.beige,
                borderRadius: '50%',
                opacity: '0.5',
              }}
            />
            <TileList
              settings={{
                ...settings,
                slidesToScroll: 2,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 3000,
                cssEase: 'linear',
              }}
              arrowYOffset={'-25px'}
              title={page.vibeList.title}
              subTitle={page.vibeList.subTitle}
              tiles={page.vibeList.vibeList}
              renderTile={
                <Card
                  shapeStyle={{
                    width: '16px',
                    height: '20px',
                    backgroundColor: '#BC8046',
                    clipPath: 'polygon(0% 0%, 25% 0%, 100% 100%, 75% 100%)',
                    marginBottom: '22px',
                    position: 'relative',
                    transform: 'unset',
                  }}
                />
              }
            />
          </div>

          <IndexArtisans
            settings={settings}
            title={page.artisanList.title}
            subTitle={page.artisanList.subTitle}
            artisans={page.artisanList.artisans}
          />
          <div className="thematic-menus-container">
            <ParallaxShape
              x={0}
              y={-40}
              shapeStyle={{
                width: '200px',
                height: '400px',
                border: '140px solid ' + colors.brand.beige,
                borderRight: 'none',
                borderBottomLeftRadius: '800px',
                borderTopLeftRadius: '800px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                opacity: '0.5',
              }}
            />
            <TileList
              settings={{
                ...settings,
                rows: 1,
                slidesToShow: 2.999,
                speed: 500,
                lazyLoad: 'progressive',
                arrows: false,
                responsive: [
                  {
                    breakpoint: breakpoints.desktop + 1,
                    settings: {
                      rows: 1,
                      slidesToScroll: 2,
                      slidesToShow: 2,
                      arrows: true,
                      infinite: true,
                      autoplay: true,
                      autoplaySpeed: 3000,
                    },
                  },
                  {
                    breakpoint: breakpoints.mobile + 1,
                    settings: {
                      rows: 1,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                      autoplay: true,
                      autoplaySpeed: 3000,
                      arrows: false,
                    },
                  },
                ],
              }}
              centered={true}
              title={page.bigVibeList.title}
              subTitle={page.bigVibeList.subTitle}
              tiles={page.bigVibeList.vibeList}
              renderTile={<Card cta="Découvrir" />}
            />
          </div>
          <div className="banner1-container">
            <ParallaxShape
              x={80}
              y={-45}
              shapeStyle={{
                width: '437px',
                height: '437px',
                borderRadius: '50%',
                border: '145px solid ' + colors.brand.beige,
                opacity: '0.5',
              }}
            />
            <ParallaxShape
              x={2}
              y={60}
              shapeStyle={{
                width: '200px',
                height: '400px',
                border: '140px solid ' + colors.brand.beige,
                borderLeft: 'none',
                borderBottomRightRadius: '800px',
                borderTopRightRadius: '800px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                opacity: '0.5',
              }}
            />
            <Banner {...page.banner1} />
          </div>
          <div className="events-container">
            <ParallaxShape
              x={80}
              y={-5}
              shapeStyle={{
                width: '400px',
                height: '200px',
                border: '140px solid ' + colors.brand.beige,
                borderBottom: 'none',
                borderTopLeftRadius: '400px',
                borderTopRightRadius: '400px',
                transform: 'matrix(-0.87, 0.5, -0.5, -0.87, 0, 0)',
                opacity: '0.5',
              }}
            />
            <ParallaxShape
              x={10}
              y={25}
              shapeStyle={{
                width: '400px',
                height: '400px',
                border: '140px solid ' + colors.brand.beige,
                borderRadius: '50%',
                opacity: '0.5',
              }}
            />
            <TileList
              settings={{
                ...settings,
                rows: 2,
                slidesToShow: 2.999,
                speed: 500,
                lazyLoad: 'progressive',
                arrows: false,
                responsive: [
                  {
                    breakpoint: breakpoints.desktop + 1,
                    settings: {
                      rows: 1,
                      slidesToScroll: 2,
                      slidesToShow: 2,
                      arrows: true,
                      infinite: true,
                      autoplay: true,
                      autoplaySpeed: 3000,
                    },
                  },
                  {
                    breakpoint: breakpoints.mobile + 1,
                    settings: {
                      rows: 1,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                      autoplay: true,
                      autoplaySpeed: 3000,
                      arrows: false,
                    },
                  },
                ],
              }}
              centered={true}
              title={page.eventShowcaseList.title}
              subTitle={page.eventShowcaseList.subText}
              tiles={page.eventShowcaseList.events}
              renderTile={<EventCard />}
            />
          </div>
          <GoogleRatingContainer />
          <DoubleBanner left={page.banner2} right={page.banner3} />
          <BottomBanner bottomBanner={page.bottomCta} />
        </div>
        <Footer />
      </ParallaxProvider>
    </StyledIndexPage>
  )
}

const StyledIndexPage = styled.div`
  background-color: #f5f5f5;
  overflow: hidden;
  .logo-stamp {
    position: absolute;
    top: 64%;
    left: 71%;
    z-index: 2;
  }
  .banner {
    width: 90%;
    height: 100%;
    background: #f0e4d2 0% 0% no-repeat padding-box;
    clip-path: polygon(0% 0%, 98% 0%, 75% 100%, 0 100%);
    display: flex;
    position: relative;
    z-index: 1;
  }

  .google-rating-container {
    position: absolute;
    bottom: 0;
    right: 5%;
    .google-rating {
      width: 200px;
      height: 120px;
      background: #f5f5f5;
      border-radius: 24px 24px 0 0;
      z-index: 1;
    }
  }

  ${StyledTileList} {
    position: relative;
    width: ${homeLayout.width};
    margin: auto;
    margin-top: 100px;
  }
    h1 {
      color: ${colors.brand.normal};
      font-size: 60px;
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 2.2rem;
    }
    h2 {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 20px;
      padding-left: 5px;
      color: ${colors.brand.black};
    }
    ${StyledEventFormatSelector} {
      ${StyledButton}.format {
        border-color: ${borders.color.normal};
        color: ${colors.brand.black};
        padding: 16px 14px 16px 30px;

        &:not(:last-child):hover {
          color: ${colors.brand.normal};
        }
        &:last-child {
          color: ${colors.brand.inverted};
          padding: 16px 14px 16px;
        }
      }
    }
  }
  .our-menus-container {
    position: relative;
  }
  .thematic-menus-container {
    position: relative;
    margin-top: 120px;
  }
  .banner1-container {
    position: relative;
    margin-top: 185px;
  }
  .events-container {
    position: relative;
    margin-top: 171px;
  }

  @media (max-width: 1275px) {
    .banner {
      width: 95%;
    }
    .logo-stamp {
      display: none;
    }
  }

  @media (max-width: 1150px) {
    .banner {
      width: 100%;
    }
  }
  @media (max-width: 1100px) {
    .banner {
      clip-path: unset;
    }
    .banner-img-container {
      background: unset;
    }
    .shapes {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.mobile}px) {
    ${StyledLargeBanner} {
      min-height: 450px;

      .text-wrapper {
        padding-right: 0;
        width: 100%;
      }

      .actions {
        flex-direction: column;
      }
      ${StyledButton}.order {
        margin: auto;
        margin-top: 15px;
      }
    }
    .artisan-tile-list {
      .slick-list {
        margin-left: -5px;
        margin-right: -5px;
      }
      .slick-slide {
        padding: 5px;
      }
    }
  }
  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    .logo-stamp {
      left: 69%;
    }
    .google-rating-container {
      right: 3%;
      .google-rating {
        width: 200px;
        height: 100px;
        img {
          max-width: 45%;
        }
        .rating {
          margin-bottom: 5px;

          .rating-value {
            font-size: 15px;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.desktop}px) {
    .our-menus-container
      ${StyledParallaxShape},
      .thematic-menus-container
      ${StyledParallaxShape},
      .our-artisans-container
      ${StyledParallaxShape},
      .banner1-container
      ${StyledParallaxShape},
      .events-container
      ${StyledParallaxShape} {
      display: none;
    }
    .google-rating-container {
      left: 20%;
      right: 20%;
      width: 60%;
      bottom: -40px;
      z-index: 1;
      .google-rating {
        width: 100%;
        max-width: 300px;
        margin: auto;
        height: 100px;
        background: #f5f5f5;
        border-radius: 24px 24px 0 0;
      }
    }
    ${StyledTileList} {
      margin-top: 70px;
      width: 100%;
    }
    .our-menus-container {
      width: 85%;
      margin: auto;
    }
    .thematic-menus-container {
      width: 85%;
      margin: auto;
    }
    .events-container {
      width: 85%;
      margin: auto;
    }
  }
`

export const query = graphql`
  {
    page: contentfulHomePage(fixedId: { eq: "homepage-1" }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      seoKeywords {
        seoKeywords
      }
      bannerHeader {
        ...IndexBannerHeaderFragment
      }
      artisanList {
        ...IndexArtisansFragment
      }
      vibeList {
        title
        subTitle
        vibeList {
          ...VibeFragment
        }
      }
      bigVibeList {
        title
        subTitle
        vibeList {
          ...VibeFragment
        }
      }
      eventShowcaseList {
        title
        subText
        events {
          ...EventShowcaseFragment
        }
      }
      banner1 {
        ...BannerFragment
      }
      banner2 {
        ...BannerFragment
      }
      banner3 {
        ...BannerFragment
      }
      banner4 {
        ...BannerFragment
      }
      bottomCta {
        ...CallToActionFragment
      }
    }
  }
`

export default IndexPage
