import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, StyledButton } from '@monbanquet/crumble'
import { colors, breakpoints } from '../theme'
import { navigate } from './Link'
import { EventContext } from './context/EventContext'
import Arrow from '../assets/arrow.svg'
import { computeNextPage, withBaseUri } from '../util/compute-next-page'
import Slider from 'react-slick'
import mailIcon from '../assets/envelope-regular.svg'

function isMonbanquetClosed() {
  const now = Date.now()
  const startClosingDate = new Date('2019-12-24T17:00:00')
  const endClosingDate = new Date('2020-01-02T12:00:00')
  return now > startClosingDate && now < endClosingDate
}

function isMonbanquetClosedCoronaVirus() {
  return true
}

const settings = {
  infinite: true,
  slidesToScroll: 1,
  vertical: true,
  arrows: false,
  slidesToShow: 7,
  speed: 500,
  dots: false,
  autoplay: true,
  autoplaySpeed: 3000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: breakpoints.desktop + 1,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: breakpoints.tablet + 1,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: breakpoints.mobile + 1,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const EventFormatSelector = () => {
  const { categories, dispatch } = useContext(EventContext)

  const selectEventFormat = slug => {
    dispatch({
      type: 'UPDATE_CATEGORY',
      payload: { category: slug, multiMoment: false },
      analytics: { label: 'From_EventFormatSelector' },
    })

    const addBaseUri = withBaseUri('demande')
    navigate(addBaseUri(computeNextPage('root')), { state: { next: true } })
  }

  const selectMultiMoment = () => {
    dispatch({
      type: 'UPDATE_IS_MULTI_MOMENT',
      payload: { category: null, multiMoment: true },
      analytics: { label: 'From_EventFormatSelector' },
    })

    const addBaseUri = withBaseUri('demande')
    navigate(
      addBaseUri(computeNextPage('root', null, { isMultiMoments: true })),
      {
        state: { next: true },
      },
    )
  }

  const mailTo = () => {
    const subject = encodeURIComponent('Demande spécifique / Site')
    const body = encodeURIComponent('')
    const mailtoLink = `mailto:sales@monbanquet.fr?subject=${subject}&body=${body}`
    window.location.href = mailtoLink
  }

  return (
    <StyledEventFormatSelector>
      <div className="formats">
        <Slider {...settings}>
          {categories.map(cat => (
            <div className="container" key={cat.slug}>
              <Button
                className="format"
                onClick={() => selectEventFormat(cat.slug)}
              >
                <div className="name">{cat.title}</div>
                <div className="suggested-hours">{cat.suggestedHours}</div>
                <div className="cta">
                  <em>Obtenir un devis</em>
                </div>
                <div className="arrow">
                  <img src={Arrow} alt="arrow" />
                </div>
              </Button>
            </div>
          ))}
        </Slider>
        <Button className="format multi-moment" onClick={mailTo}>
          <div className="name">
            <img className="mail-icon" src={mailIcon} alt="mail" />
            <span className="mail-to-text">
              {' '}
              {`Pour vos évènements plus spécifiques, écrivez nous ici ! `}
            </span>
            {/*  <span className="underline">{`plusieurs créneaux`}</span> */}
          </div>
        </Button>
      </div>
      {/*<p className="hint-text">
        
         isMonbanquetClosed()
          ? `Recevez un devis à partir du 2 Janvier 2020`
        : `Recevez un devis en moins de 3 heures`
      </p>*/}
      <p className="locations">{`Paris | Lyon`}</p>
    </StyledEventFormatSelector>
  )
}

const StyledEventFormatSelector = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  padding: 0 30px;

  .mail-icon {
    max-width: 25px;
    margin-right: 10px;
    position: relative;
    top: -5px;
  }

  .mail-to-text {
    position: relative;
    top: -12px;
  }

  .hint-text {
    color: ${colors.brand.green};
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  }

  .container {
    padding: 8px 0;
  }

  .formats {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .slick-slider {
      overflow: hidden;
    }

    ${StyledButton} {
      text-transform: none;
      letter-spacing: unset;
      text-align: left;
      height: 66px;
    }

    ${StyledButton}.format {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;

      padding: 16px 25px;
      border-radius: 37px;
      background-color: #fff;

      .name {
        color: ${colors.brand.normal};
        flex-grow: 3;
        font-weight: 500;
        font-size: 19px;
      }

      .suggested-hours {
        color: ${colors.brand.normal};
        font-size: 19px;
        opacity: 0.7;
        text-align: right;
      }

      .arrow {
        text-align: right;
        font-size: 20px;
        align-items: center;
        justify-content: flex-end;
        display: none;

        img {
          transform: rotate(180deg);
          max-width: 25px;
        }
      }

      .cta {
        background: ${colors.brand.inverted};
        display: none;
        transition: opacity 150ms ease-in-out;
        font-size: 17px;
        color: ${colors.brand.normal};

        em {
          font-size: 17px;
          font-style: normal;
          font-weight: bold;
          margin-right: 5px;
        }
      }

      &.multi-moment {
        text-align: center;
        background-color: ${colors.brand.normal};
        .name {
          color: ${colors.brand.inverted};
          font-weight: normal;
          flex-grow: 1;
        }
      }

      &:hover {
        box-shadow: none;

        &:not(:last-child) {
          background-color: ${colors.brand.inverted};
        }

        .suggested-hours {
          display: none;
        }

        .arrow,
        .cta {
          display: inline;
        }
      }

      & > :not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .locations {
    color: #383838;
    font-size: 15px;
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
  }

  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    .hint-text {
      font-size: 15px;
      margin-bottom: 10px;
    }
    .formats {
      ${StyledButton}.format {
        padding: 16px 25px;

        .name {
          font-size: 16px;
        }

        .cta {
          em {
            font-size: 14px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 13px;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .hint-text {
      margin-bottom: 14px;
      color: ${colors.brand.green};
      font-size: 17px;
      text-align: center;
      font-weight: bold;
    }

    .formats {
      ${StyledButton} {
        height: 48px;
        &.format {
          padding: 10px 25px;

          &:not(:last-child) {
            margin-bottom: 14px;
          }
          .name {
            font-size: 17px;
          }

          .suggested-hours {
            font-size: 17px;
            display: none;
          }

          .arrow {
            flex-grow: 1;
            text-align: right;
            font-size: 17px;
          }
        }
      }
    }
    .locations {
      margin-top: 5px;
      font-size: 12px;
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    padding: 20px 5px 30px;

    .hint-text {
      display: none;
    }

    .formats {
      ${StyledButton} {
        &.format {
          .name {
            flex-basis: 80%;
            font-size: 15px;
          }

          .cta {
            em {
              margin-right: 0;
            }
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &:last-child {
            .name {
              font-size: 12px;
            }
          }

          &:hover {
            .cta {
              display: none;
            }
          }
        }
      }
    }
    .locations {
      margin-top: 10px;
      font-size: 9px;
      text-transform: uppercase;
      margin-bottom: 50px;
    }
  }
`

export default EventFormatSelector
export { StyledEventFormatSelector }
