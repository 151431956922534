import React from 'react'
import styled from 'styled-components'
import LogoStamp from '../../assets/logo-stamp.svg'
import ParallaxShape, { StyledParallaxShape } from '../ParallaxShape'
import EventFormatSelector, {
  StyledEventFormatSelector,
} from '../EventFormatSelector'
import Link from '../Link'
import GoogleRating from '../GoogleRating'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { borders, breakpoints, colors, layout } from '../../theme'
import { StyledButton } from '@monbanquet/crumble'

const IndexBannerHeader = ({ title, subTitle, picture }) => {
  return (
    <StyledIndexBannerHeader>
      <div className="banner-container">
        <img src={LogoStamp} className="logo-stamp" alt="" />
        <div className="banner">
          <ParallaxShape
            x={60}
            y={-15}
            shapeStyle={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              border: '10px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={8}
            y={50}
            shapeStyle={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              border: '10px solid #BC8046',
            }}
          />
          <ParallaxShape
            x={5}
            y={28}
            shapeStyle={{
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              border: '12px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={70}
            y={3}
            shapeStyle={{
              width: '35px',
              height: '19px',
              border: '10px solid #030101',
              borderBottom: 'none',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
            }}
          />
          <ParallaxShape
            x={8}
            y={12}
            shapeStyle={{
              width: '26px',
              height: '33px',
              top: '30%',
              left: '-40%',
              background: '#BC8046 0% 0% no-repeat padding-box',
              clipPath: 'polygon(0% 0%, 25% 0%, 100% 100%, 75% 100%)',
            }}
          />
          <div className="top-cta-header">
            <div className="form">
              <h1>{title}</h1>
              <div className="shape"></div>
              <h2>{subTitle}</h2>
              <EventFormatSelector />
            </div>
          </div>
        </div>
        <div className="banner-picture">
          <StyledImg fluid={picture.fluid} alt={picture.title} />
        </div>
        <div className="google-rating-container">
          <div className="google-rating">
            <Link
              to={`https://www.google.com/search?q=monbanquet&oq=monbanquet
                           &aqs=chrome..69i57j69i60l3j69i59.1416j0j7&sourceid=chrome&ie=
                           UTF-8#lrd=0x47e66e76a78ab02d:0xdc91ff2723f1b0f1,1,,,`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GoogleRating small={true} />
            </Link>
          </div>
        </div>
      </div>
    </StyledIndexBannerHeader>
  )
}

const StyledImg = styled(Img)`
  position: absolute;
  left: 20%;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`

const StyledIndexBannerHeader = styled.form`
  .banner-container {
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-position: 121%;
    background-repeat: no-repeat;
    background-size: contain;

    .message-bold {
      font-weight: bold;
    }
    .banner-picture {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      width: 51%;
      align-self: center;
    }
  }
  .top-cta-header {
    max-height: ${layout.topCtaHeaderFormHeight.normal};
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: auto;
    display: flex;
    position: relative;
    margin-top: 5%;
    z-index: 1;
    .form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 34px;
      border-radius: 4px;
      font-size: 19px;
      margin-left: 5%;
    }
    @media (max-width: 1100px) {
      .top-cta-header .form {
        margin: 60px auto;
      }
    }
  }

  @media (max-height: 810px) and (min-width: ${breakpoints.desktop}px) {
    .top-cta-header {
      display: flex;
      position: relative;
      margin-top: 6%;
      .form {
        margin-top: 0;
        padding: 15px 17px;
        font-size: 19px;
        margin-left: 130px;
      }
      h1 {
        color: ${colors.brand.normal};
        font-size: 45px;
        margin-bottom: 15px;
      }
      h2 {
        font-size: 21px;
        font-weight: normal;
        margin-bottom: 30px;
        padding-left: 5px;
        color: ${colors.brand.black};
      }
      ${StyledEventFormatSelector} {
        ${StyledButton}.format {
          border-color: ${borders.color.normal};
          color: ${colors.brand.black};
          padding: 8px 10px 8px 26px;
          height: 40px;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.desktop}px) {
    .top-cta-header {
      margin-top: 85px;
      .form {
        margin: 0 auto;
        padding: 0 5vw;
        background: transparent;
        padding-bottom: 0;
      }
      h1 {
        font-size: 35px;
        line-height: 40px;
        text-align: center;
      }
      .shape {
        margin: 13px auto;
        width: 23px;
        height: 23px;
        background: ${colors.brand.normal};
        clip-path: polygon(80% 0, 100% 0, 20% 100%, 0 100%);
      }
      h2 {
        text-align: center;
        font-size: 15px;
        margin-bottom: 0;

        &:first-letter {
          text-transform: uppercase;
        }
      }
      ${StyledEventFormatSelector} {
        ${StyledButton}.format {
          padding: 14px 16px 14px 20px;
        }
      }
    }
    .banner-container ${StyledParallaxShape} {
      display: none;
    }
    .banner-container {
      height: unset;
      overflow: visible;
    }
  }
`

export default IndexBannerHeader
export { StyledIndexBannerHeader }

export const IndexBannerHeaderFragment = graphql`
  fragment IndexBannerHeaderFragment on ContentfulBanner {
    title
    subTitle
    picture {
      title
      fluid(maxWidth: 900, quality: 90) {
        aspectRatio
        src
        srcSet
        sizes
        srcWebp
        srcSetWebp
      }
    }
  }
`
